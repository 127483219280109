import Typography, { TypographyOptions } from "typography";
import CodePlugin from "typography-plugin-code";
// const theme = oceanBeachTheme as TypographyOptions;
const theme: TypographyOptions = {
  title: "Aplinet",
  baseFontSize: "19px",
  baseLineHeight: 1.58,
  googleFonts: [],
  headerFontFamily: ["Roboto", "sans-serif"],
  bodyFontFamily: ["Roboto", "sans-serif"],
  headerColor: "hsla(0,0%,0%,0.9)",
  bodyColor: "hsla(0,0%,0%,0.73)",
  headerWeight: 700,
  bodyWeight: 400,
  boldWeight: 700,
} as TypographyOptions;
theme.plugins = [new CodePlugin()];

theme.overrideThemeStyles = ({ rhythm }, options) => ({
  
  ".menu-overlay h2": {
    color: "#fff",
    marginBottom: rhythm(1 / 2),
  },
  ".menu-overlay li": {
    fontWeight: "bold",
  },
  "footer.site-footer h4": {
    marginBottom: rhythm(1 / 2),
    marginTop: rhythm(1 / 2),
  },
  footer: {
    ...scale(-1 / 4),
    lineHeight: rhythm(0.7),
  },
 
});
const typography = new Typography(theme);

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
