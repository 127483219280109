import { Link } from "gatsby";
import React from "react";
import MenuNavbar from "./menu-navbar";
import MenuOverlay from "./menu-overlay";
import Hamburger from "../../images/svg/hamburger.inline.svg";

import "./header.scss";
import { PageContext, PostPageContext } from "../../../types";

interface State {
  active: boolean;
  menuVisible: boolean;
}
interface Props {
  context: PageContext | PostPageContext;
}
class Header extends React.Component {
  static defaultProps = {};
  state: State = {
    active: false,
    menuVisible: false,
  };
  menuOverlay: MenuOverlay | null = null;
  constructor(public props: Props) {
    super(props);
    this.activeCallback = this.activeCallback.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  activeCallback(value: boolean) {
    if (this.menuOverlay) {
      if (value) {
        this.setState({ menuVisible: true });
      } else {
        this.setState({ menuVisible: false });
      }
      this.menuOverlay.activate(value);
    }
  }
  toggleMenu(event: MouseEvent) {
    if (!this.state.menuVisible) {
      this.activeCallback(true);
    } else {
      this.activeCallback(false);
    }
  }
  render() {
    return (
      <>
        <MenuOverlay
          context={this.props.context}
          ref={menuOverlay => {
            this.menuOverlay = menuOverlay;
          }}
          menuVisible={this.state.menuVisible}

          activeCallback={this.activeCallback}
        
        />
        <button
          title="Pokaż menu"
          className={
            `hamburger-button on-overlay ` +
            (this.state.menuVisible ? "active" : "")
          }
          type="button"
        >
          {" "}
          <Hamburger onClick={this.toggleMenu} />
        </button>
        <MenuNavbar
          context={this.props.context}
          menuVisible={this.state.menuVisible}
          activeCallback={this.activeCallback}
        />
      </>
    );
  }
}

export default Header;
