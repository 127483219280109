/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import "./footer.scss";
import Technologies from "./footer-technologies";
import Contact from "./footer-contact";
import Cities from "./footer-cities";
import Offer from "./footer-offer";
import { scale, rhythm } from "../../utils/typography";
import ALink from "../a-link/a-link";
import { PageContext, PostPageContext } from "../../../types";

interface Props {
  children?: any;
  context: PageContext | PostPageContext;
}

class Footer extends React.Component {

  constructor(public props: Props) {
    super(props);
  }

  render() {
    return (
      <footer
        className="site-footer container"
        itemScope
        itemType="http://schema.org/WPFooter"
      >
        <div className="r :c-6:xs :c-6:xxs :c-3 :w">
          <div className="offer-column">
            <Offer language={this.props.context.language} />
          </div>

          <div className="cities-column">
            <Cities language={this.props.context.language} />
          </div>
          <div className="technologies-column">
            <Technologies language={this.props.context.language} />
          </div>
          <div className="contact-column">
            <Contact language={this.props.context.language} />
          </div>
        </div>

        <div
          className="copyrights air-m:3"
          style={{
            ...scale(-0.3),

          }}
        >
          <span className="line">© Copyright 2020 Aplinet</span><span className="line">All Rights Reserved.</span>
          <span className="line">
            {this.props.context.language === 'pl' ? <Link
              title="Polityka Prywatności"
              to="/polityka-prywatnosci"
            >
              Polityka prywatności
          </Link> : <Link
                title="Privacy policy"
                to="/en/privacy-policy"
              >
                Privacy policy
          </Link>}

          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
