/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Link } from "gatsby";

import { Config } from "../../../config";

interface Props {
  language: string;
  to: string;
  children: any;
  title?: string;
  partiallyActive?: boolean;
  itemProp?: string;
  className?: string;
  activeClassName?: string;
  activeStyle?: { [key: string]: any };
  [key: string]: any;
}

const ALink = (props: Props) => {
  let href = props.to;
  if (props.language !== Config.defaultLanguage && props.language) {
    href = (props.to.startsWith('/') ? '/' : '') + props.language + href;
  }

  function filteredProps() {
    const filteredProps = { ...props };
    delete filteredProps.children;
    delete filteredProps.language;
    filteredProps.to = href;
    return filteredProps;
  }
  return <Link {...filteredProps()}>{props.children}</Link>;
};

export default ALink;
