/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  children?: any;
}

const Cities = ({ children, language }: Props) => {
  const cities = [
    { url: "/poznan-strony-www-aplikacje", label: "Poznań" },
    { url: "/zielona-gora-strony-www-aplikacje", label: "Zielona Góra" },
    { url: "/bydgoszcz-strony-www-aplikacje", label: "Bydgoszcz" },
    { url: "/pila-strony-www-aplikacje", label: "Piła" },
    { url: "/gorzow-wlkp-strony-www-aplikacje", label: "Gorzów Wlkp." },
    { url: "/leszno-strony-www-aplikacje", label: "Leszno" },
    { url: "/kalisz-strony-www-aplikacje", label: "Kalisz" },
    { url: "/wroclaw-strony-www-aplikacje", label: "Wrocław" },
    { url: "/lodz-strony-www-aplikacje", label: "Łódź" },
    { url: "/torun-strony-www-aplikacje", label: "Toruń" },
    { url: "/warszawa-strony-www-aplikacje", label: "Warszawa" },
    { url: "/ostrow-wlkp-strony-www-aplikacje", label: "Ostrów Wlkp." },
    { url: "/ostrzeszow-strony-www-aplikacje", label: "Ostrzeszów" },
    { url: "/konin-strony-www-aplikacje", label: "Konin" },
    { url: "/katowice-strony-www-aplikacje", label: "Katowice" },
    { url: "/gdansk-strony-www-aplikacje", label: "Gdańsk" },
    { url: "/gdynia-strony-www-aplikacje", label: "Gdynia" },
    { url: "/krakow-strony-www-aplikacje", label: "Kraków" },
    { url: "/lublin-strony-www-aplikacje", label: "Lublin" },
  ].map((city, index) => {
    return (
      <li key={index}>
        <a href={city.url} rel="noreferrer">
          <span itemProp="name">{city.label}</span>
        </a>
      </li>
    );
  });

  return (
    <>
      <h4>{language === "pl" ? "Nasz zasięg" : "Locations"}:</h4>
      <nav>
        <ul className="r no-padding :c-6 :w">{cities}</ul>
      </nav>
    </>
  );
};

export default Cities;
