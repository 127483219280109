import { Config } from "../../config";

/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
export const EasingFunctions = {
  // no easing, no acceleration
  linear: (t:number) => t,
  // accelerating from zero velocity
  easeInQuad: (t:number) => t * t,
  // decelerating to zero velocity
  easeOutQuad: (t:number) => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t:number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: (t:number) => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: (t:number) => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t:number) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // accelerating from zero velocity
  easeInQuart: (t:number) => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: (t:number) => 1 - --t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t:number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
  // accelerating from zero velocity
  easeInQuint: (t:number) => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: (t:number) => 1 + --t * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t:number) =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
};

export function joinUrls(...urlFragments: string[]) {
  const cleanFragments = urlFragments.map((urlFragment, index) => {
    if (index === 0) {
      return urlFragment.endsWith("/")
        ? urlFragment.slice(0, urlFragment.length - 1)
        : urlFragment;
    }
    if (index === urlFragments.length - 1) {
      return urlFragment.startsWith("/") ? urlFragment.slice(1) : urlFragment;
    }

    urlFragment = urlFragment.endsWith("/")
      ? urlFragment.slice(0, urlFragment.length - 1)
      : urlFragment;

    urlFragment = urlFragment.startsWith("/")
      ? urlFragment.slice(1)
      : urlFragment;
    return urlFragment;
  });

  return cleanFragments.join("/");
}

export function generateI18nSlug(slug: string, language: string) {
  let result = "";
  if (Config.defaultLanguage === language) {
    result = slug;
  } else {
    result = joinUrls(language, slug);
  }
  return result;
}

export function getScrollTop(): number {
  if (typeof pageYOffset != "undefined") {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
}

export function arrayChunk(
  array: any[],
  chunk_size: number,
  callback: (chunk: any[], index: number) => any
) {
  let index = 0;
  for (let i = 0; i < array.length; i += chunk_size) {
    const chunk = array.slice(i, i + chunk_size);
    ++index;
    callback(chunk, index);
  }
}

/**
 * Re-maps a number from one range to another.
 * @method map
 * @param  {Number} value  the incoming value to be converted
 * @param  {Number} start1 lower bound of the value's current range
 * @param  {Number} stop1  upper bound of the value's current range
 * @param  {Number} start2 lower bound of the value's target range
 * @param  {Number} stop2  upper bound of the value's target range
 * @param  {Boolean} [withinBounds] constrain the value to the newly mapped range
 * @return {Number}        remapped number
 */
export function map(
  value: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number,
  withinBounds: boolean
) {
  const newval =
    ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return constrain(newval, start2, stop2);
  } else {
    return constrain(newval, stop2, start2);
  }
}

export function constrain(value: number, low: number, high: number) {
  return Math.max(Math.min(value, high), low);
}

export function getMouseWheelEvent() {
  if (!document || !window) {
    return;
  }
  const doc = document;
  const win = window;
  var eventName = "onwheel";
  var isSupported = eventName in doc;

  if (!isSupported) {
    const element: any = doc.createElement("div");
    element.setAttribute(eventName, "return;");
    isSupported = typeof element[eventName] === "function";
  }

  if (
    !isSupported &&
    doc.implementation &&
    doc.implementation.hasFeature &&
    // always returns true in newer browsers as per the standard.
    // @see http://dom.spec.whatwg.org/#dom-domimplementation-hasfeature
    doc.implementation.hasFeature("", "") !== true
  ) {
    // This is the only way to test support for the `wheel` event in IE9+.
    isSupported = doc.implementation.hasFeature("Events.wheel", "3.0");
  }
  if (win.navigator.userAgent.indexOf("firefox") > -1) {
    return "DOMMouseScroll";
  }
  return isSupported ? "wheel" : "mousewheel";
}

export interface IScrollToOptions {
  duration?: number;
  speed?: number | null;
  onFinish?: null | ((notCanceled: boolean) => any);
  easing: (t: number) => number;
}
const defaultScrollToOptions: IScrollToOptions = {
  duration: 500,
  speed: null,
  onFinish: null,
  easing: (t: number) => t,
};
export function scrollTo(
  parent: HTMLElement,
  top: number,
  params: IScrollToOptions
): () => void {
  // cancel if already on top
  const options = Object.assign(defaultScrollToOptions, params);

  if (parent.scrollTop === top) {
    return () => {};
  }

  const totalScrollDistance = top - parent.scrollTop;

  let duration = options.duration;
  if (options.speed) {
    duration = Math.abs(totalScrollDistance / options.speed) * 1000;
  }
  if (!duration) {
    duration = 500;
  }
  let scrollY = parent.scrollTop;
  let oldTimestamp: any = null;
  let cancel = false;

  const step = (newTimestamp: any) => {
    if (cancel) {
      if (options.onFinish) {
        options.onFinish(false);
      }
      return true;
    }

    if (oldTimestamp !== null) {
      // if duration is 0 scrollY will be -Infinity

      const t = map(
        newTimestamp - oldTimestamp,
        0,
        duration as number,
        0,
        1,
        true
      );

      parent.scrollTop = scrollY + totalScrollDistance * options.easing(t);
      if (t === 1) {
        if (options.onFinish) {
          options.onFinish(true);
        }
        return true;
      }
      window.requestAnimationFrame(step);
    } else {
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
  return () => {
    cancel = true;
  };
}
