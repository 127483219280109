import { Link } from "gatsby";
import React from "react";
import { setGracefulCleanup } from "tmp";

interface Props {
  refCallback: (element: HTMLElement) => void;
}

class TileBackground extends React.Component {
  tileWrapper: string = ` <svg class="background" preserveAspectRatio="none" xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 10000 10000" version="1.1" id="svg8">
  <g id="menuClipPath">
  </g>
  </svg>`;

  constructor(public props: Props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }
  setRef(element: HTMLElement) {
    if (typeof this.props.refCallback === "function") {
      this.props.refCallback(element);
    }
  }

  render() {
    return (
      <>
        <div className="tile-background-wrapper"
          ref={this.setRef}
          dangerouslySetInnerHTML={{ __html: this.tileWrapper }}
        ></div>
      </>
    );
  }
}

export default TileBackground;
