/// <reference types="svg-engine"
/* eslint-disable */
/* tslint:disable */

import { gsap, Power0, Power3, Circ } from "gsap";

export function TriangleParticle(options) {
  var points = null;
  var y = options.y;
  var x = options.x;
  var particleWidth = options.particleWidth
    ? options.particleWidth
    : (options.canvasWidth * 2) / options.countX;
  var particleHeight = options.particleHeight
    ? options.particleHeight
    : options.canvasHeight / options.countY;
  var relativeY = y * particleHeight - y;
  var relativeX = (x * particleWidth) / 2 - particleWidth / 2;
  var className = null;
  points = [
    [relativeX, relativeY],
    [relativeX + particleWidth, relativeY],
    [relativeX + particleWidth / 2, relativeY + particleHeight],
  ];
  if ((y % 2 === 0 && x % 2 !== 0) || (y % 2 !== 0 && x % 2 === 0)) {
    className = "invert";
    var relativeY = y * particleHeight + particleHeight;
    points = [
      [relativeX, relativeY],
      [relativeX + particleWidth, relativeY],
      [relativeX + particleWidth / 2, relativeY - particleHeight],
    ];
  }

  return {
    column: x,
    row: y,
    width: particleWidth,
    height: particleHeight,
    relativeX: relativeX,
    relativeY: relativeY,
    points: points,
    class: className,
  };
}
TriangleParticle.prototype.createElement = function(particleInstance, style) {
  if (particleInstance.points) {
    var pointsString = particleInstance.points
      .map(function(point) {
        return point[0] + "," + point[1];
      })
      .join(" ");

    var element = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    element.setAttribute("points", pointsString);
    if (particleInstance.class) {
      element.setAttribute("class", "shape " + particleInstance.class);
    } else {
      element.setAttribute("class", "shape");
    }

    let originX = Math.round(
      particleInstance.relativeX + particleInstance.width / 2
    );
    let originY = Math.round(
      particleInstance.relativeY + particleInstance.height / 2
    );
    // if (particleInstance.class === "") {
    //   originX += particleInstance.relativeX;
    //   originY += particleInstance.relativeY;
    // }

    // element.setAttribute("data-svg-origin", `${originX},${originY}`);
    // element.setAttribute(
    //   "transform",
    //   `matrix(0.5,0,0,0.5,${originX / 2},${originY / 2})`
    // );
    element.setAttribute(
      "style",
      `transform-origin: 0px 0px;
      stroke-width: 24;
      fill: #50817b;
      stroke:#50817b;
      opacity: 0;`
    );

    return element;
  }
  return null;
};

const particleSize = 300;
export default function SVGEngine(svgElement) {
  var self = this;
  this.options = {
    countX: 4,
    countY: 2,
    duration: 600,
  };

  this.playTimeline = null;
  this.transitionMainProperty = null;
  this.events = [];
  this.element = svgElement;

  this.viewBox = this.element.viewBox.baseVal;

  this.particles = [];
  this.container = this.element.getElementById("menuClipPath");

  this.countParticles = function() {
    if (window) {
      this.options.clientHeight = window.innerHeight;
      this.options.clientWidth = window.innerWidth;
    }
    const proportion = this.options.clientWidth / this.options.clientHeight;
    if (proportion > 1) {
      //width bigger
      this.options.countX = Math.ceil(this.options.clientWidth / particleSize);
      this.options.particleWidth = this.viewBox.width / this.options.countX;
      this.options.countX *= 2;
      this.options.particleHeight = this.options.particleWidth * proportion;
      this.options.countY = Math.ceil(
        this.viewBox.height / this.options.particleHeight
      );
    } else {
      //height bigger
      this.options.countY = Math.ceil(this.options.clientHeight / particleSize);
      this.options.particleHeight = this.viewBox.height / this.options.countY;

      this.options.particleWidth = this.options.particleHeight / proportion;
      this.options.countX = Math.ceil(
        this.viewBox.width / this.options.particleWidth
      );
      this.options.countX *= 2;
    }
  };
  this.countParticles();
  this.generateParticles = function() {
    this.particles = [];
    for (var y = 0; y < this.options.countY; y++) {
      for (var x = 0; x <= this.options.countX; x++) {
        this.particles.push(
          new TriangleParticle({
            x: x,
            y: y,
            canvasWidth: this.viewBox.width,
            canvasHeight: this.viewBox.height,
            particleWidth: this.options.particleWidth,
            particleHeight: this.options.particleHeight,
            countX: this.options.countX,
            countY: this.options.countY,
          })
        );
      }
    }
  };
  this.createParticles = function() {
    const style = "";
    // const style =
    //   'transform: scale(0.5);opacity: 0;fill: #50817b;stroke: #50817b;stroke-width: 24;';
    const fragment = document.createDocumentFragment();
    for (var i = 0; i < this.particles.length; i++) {
      var particleElement = TriangleParticle.prototype.createElement(
        this.particles[i],
        style
      );
      if (particleElement) {
        // gsap.set(particleElement, {
        //     transformOrigin: '50% 50%'
        // });
        this.particles[i].element = particleElement;

        fragment.appendChild(particleElement); // Note that this does NOT go to the DOM

        // this.container.appendChild(particleElement);
      }
    }
    this.container.innerHTML = "";
    this.container.appendChild(fragment); //
  };
  this.prepareParticles = function() {
    for (let i = 0; i < this.particles.length; i++) {
      if (this.particles[i].element) {
        gsap.set(this.particles[i].element, {
          scale: 0.5,
          opacity: 0,
          // y:-10,
          fill: "#50817b",
          stroke: "#50817b",
          strokeWidth: 24,
          // transformOrigin: 'center center'
        });
      }
    }
  };
  this.prepareMenuTimeline = function() {
    var menuTimeline = new gsap.timeline();
    var menuItems = document.querySelectorAll(".column-wrapper>li");
    for (var i = 0; i < menuItems.length; i++) {
      var menuItem = menuItems[i];
      menuTimeline.add(
        gsap.fromTo(
          menuItem,
          0.4,
          {
            y: -10,
          },
          {
            y: 0,
          }
        ),
        i * 0.2
      );
      menuTimeline.add(
        gsap.fromTo(
          menuItem,
          0.9,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            ease: Power3.easeIn,
          }
        ),
        i * 0.2
      );
    }
    return menuTimeline;
  };
  this.prepareTimeline = function(x, y) {
    var timeline = new gsap.timeline({
      onComplete: () => {
        self.trigger("playing:end");
        this.setState("active");
      },
      onReverseComplete: () => {
        self.trigger("reversing:end");
        this.setState("initial");
      },
    });
    timeline.pause();
    var slowestTime = 0,
      slowestParticle;
    var duration = this.options.duration;
    // if (progress && progress < duration) {
    //     duration -= progress;
    //     duration += 400;
    // }
    var maxDistance = Math.sqrt(
      Math.pow(this.viewBox.width, 2) + Math.pow(this.viewBox.height, 2)
    );
    for (let i = 0; i < this.particles.length; i++) {
      if (this.particles[i].element) {
        var xDist = Math.pow(
          x - (this.particles[i].relativeX + this.particles[i].width / 2),
          2
        );
        var yDist = Math.pow(
          y - (this.particles[i].relativeY - this.particles[i].height / 2),
          2
        );
        var distance = Math.sqrt(xDist + yDist);
        var random = Math.random();
        var time = (distance / maxDistance) * duration;
        if (random > 0.9) {
          time = time * 2;
        }
        if (time > slowestTime) {
          slowestTime = time;
          slowestParticle = this.particles[i];
        }

        var tween = gsap.fromTo(
          this.particles[i].element,
          0.8,
          {
            scale: 0.5,
            opacity: 0,
          },
          {
            scale: 1,
            // y: 0,
            opacity: 1,
            ease: Power3.easeOut,
          }
        );
        var tween2 = gsap.to(this.particles[i].element, 0.4, {
          fill: "#1a1a1a",
          stroke: "#1a1a1a",
          // ease: Power0.easeIn,
        });
        timeline.add(tween, time / 1000);
        timeline.add(tween2, time / 1000 + 0.3);
      }
    }
    return timeline;
    // this.bindSlowestParticle(slowestParticle);
  };

  // this.onAnimationEnd = function(event) {
  //   self.lastTimestamp = null;
  //   if (event instanceof TransitionEvent) {
  //     if (
  //       self.transitionMainProperty &&
  //       self.transitionMainProperty !== event.propertyName
  //     ) {
  //       return false;
  //     } else {
  //       self.transitionMainProperty = event.propertyName;
  //     }
  //   }
  //   if (self.getState() === "reversing") {
  //     self.trigger("reversing:end");
  //   } else {
  //     self.trigger("playing:end");
  //   }
  //   // self.slowestParticle.element.removeEventListener(
  //   //   self.animationEvent,
  //   //   self.onAnimationEnd
  //   // );
  //   // self.slowestParticle.element.removeEventListener(
  //   //   self.transitionEvent,
  //   //   self.onAnimationEnd
  //   // );
  // };

  this.play = function() {
    if (this.playTimeline === null) {

      // var organizeX = Math.random() * 10000;
      // var organizeY = Math.random() * 10000;
      this.playTimeline = self.prepareTimeline(0, 5000);
      this.menuTimeline = self.prepareMenuTimeline();
      this.playTimeline.add(this.menuTimeline, "=-1.5");
    }
    var diffTimestamp = null;
    var now = performance.now();
    if (this.lastTimestamp) {
      diffTimestamp = now - this.lastTimestamp;
    }
    this.lastTimestamp = now;
    this.setState("playing");

    this.playTimeline.timeScale(1);
    this.playTimeline.play();
  };
  this.reverse = function(callback) {
    // this.unbindSlowestParticle();

    // var diffTimestamp = null;
    // var now = performance.now();

    // if (this.lastTimestamp) {
    //   diffTimestamp = now - this.lastTimestamp;
    // }
    // this.lastTimestamp = now;
    this.setState("reversing");
    // this.reverseCallback = callback;
    this.playTimeline.timeScale(-2.1);

    // setTimeout(function() {
    //     this.reverseTimeline = self.prepareTimeline(
    //         10000,
    //         5000,
    //         diffTimestamp
    //     );
    //     this.reverseTimeline.play();
    // });
  };
  this.getState = function() {
    return this.state;
  };
  this.setState = function(newState) {
    switch (newState) {
      case "playing":
        this.element.classList.add("playing");
        this.element.classList.remove("reverse-playing");
        break;
      case "active":
        this.element.classList.add("active");
        this.element.classList.remove("playing");
        this.element.classList.remove("initial");
        break;
      case "initial":
        this.element.classList.add("initial");
        this.element.classList.remove("reverse-playing");
        this.element.classList.remove("active");
        break;
      case "reversing":
        this.element.classList.add("reverse-playing");
        this.element.classList.remove("playing");
        break;
      default:
        break;
    }

    this.state = newState;
    this.trigger("state", newState);
  };
  this.trigger = function(eventName, value) {
    var relevantEvents = this.events.filter(function(event) {
      return event.name === eventName;
    });
    for (var i = 0; i < relevantEvents.length; i++) {
      relevantEvents[i].callback(value);
      if (relevantEvents[i].once) {
        this.relevantEvents.splice(i, 1);
      }
    }
  };

  this.on = function(event, callback, once) {
    this.events.push({ name: event, callback: callback, once: once });
  };
  this.off = function(eventName, callback) {
    this.events = this.events.filter(function(event) {
      if (typeof callback === "undefined" || callback === null) {
        return event.name === eventName;
      } else {
        return event.name === eventName && event.callback === callback;
      }
    });
  };

  this.init = function() {
    this.playTimeline = null;
    this.setState("initial");
    this.countParticles();
    this.generateParticles();
    this.createParticles();
  };
  this.destroy = function() {
    if (this.playTimeline) {
      this.playTimeline.pause();
    }
  };
}
