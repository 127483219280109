/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

interface Props {
  children?: any;
}

const Technologies = ({ children, language }: Props) => {
  const technologies = [
    { url: "https://angular.io/", label: "Angular" },
    { url: "https://angularjs.org", label: "AngularJs" },
    { url: "https://nativescript.org/", label: "NativeScript" },
    { url: "https://reactjs.org/", label: "React" },
    { url: "https://reactnative.dev/", label: "React Native" },
    { url: "https://vuejs.org/", label: "Vue" },
    { url: "https://laravel.com/", label: "Laravel" },
    { url: "https://flutter.dev/", label: "Flutter" },
    { url: "https://nodejs.org/en/", label: "NodeJs" },
  ].map((technology, index) => {
    return (
      <li key={index}>
        {/* <Link to={technology.url} rel="noreferrer" target="_blank">
          <span itemProp="name">{technology.label}</span>
        </Link> */}
        <a href={technology.url} rel="noreferrer" target="_blank">
          <span itemProp="name">{technology.label}</span>
        </a>
      </li>
    );
  });

  return (
    <>
      <h4>{language === 'pl' ? 'Technologie' : 'Technologies'}</h4>
      <nav>
        <ul itemScope itemType="http://schema.org/SiteNavigationElement">
          {technologies}
        </ul>
      </nav>
    </>
  );
};

export default Technologies;
