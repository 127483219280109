import { Link } from "gatsby";
import React from "react";
import Logo from "../../images/svg/aplinet_logo_full.inline.svg";
import Hamburger from "../../images/svg/hamburger.inline.svg";
import { getScrollTop } from "../../utils/utils";
import ALink from "../a-link/a-link";
import { PageContext, PostPageContext } from "../../../types";
import { GlobalState, LayoutState } from "../layout/global-state";

interface Props {
  activeCallback: (isActive: boolean) => any;
  menuVisible: boolean;
  context: PageContext | PostPageContext;
}
interface State {
  isPinned: boolean;
  isActive: boolean;
  isInactive: boolean;
  headerColor?: string | null;
  headerPinned?: boolean | null;
  headerActive?: boolean | null;
}

class MenuNavbar extends React.Component {
  static defaultProps = {};
  state: State = {
    isActive: false,
    isInactive: false,
    isPinned: false,
    headerColor: null,
    headerPinned: null,
    headerActive: null,
  };
  isActive = false;
  isPinned = false;
  scrollTop: number = 0;

  constructor(public props: Props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getHeaderClass = this.getHeaderClass.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onGlobalState = this.onGlobalState.bind(this);
    GlobalState.on(
      this.onGlobalState,
      ["headerColor", "headerPinned", "headerActive"]
    );
  }
  onGlobalState(
    state: LayoutState,
    previousState: LayoutState,
    diffState: Partial<LayoutState>
  ) {
    this.setState(diffState);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    GlobalState.off(this.onGlobalState);
  }
  handleScroll(event: Event) {
    const scrollTop = getScrollTop();
    const windowHeight = window.innerHeight;
    if (scrollTop > windowHeight / 3) {
      if (!this.isPinned) {
        this.isPinned = true;
        this.setState({ isPinned: true, isInactive: false });
      }
    } else {
      if (this.isPinned) {
        this.isPinned = false;
        this.setState({ isPinned: false, isInactive: false });
      }
    }
    if (scrollTop < this.scrollTop) {
      if (scrollTop > windowHeight / 2) {
        if (!this.isActive) {
          this.setState({ isActive: true, isInactive: false });
        }
        this.isActive = true;
      }
    } else {
      if (this.isActive) {
        this.setState({ isActive: false, isInactive: true });
      }
      this.isActive = false;
    }
    this.scrollTop = scrollTop;
  }

  toggleMenu(event: MouseEvent) {
    if (!this.props.menuVisible) {
      this.props.activeCallback(true);
    } else {
      this.props.activeCallback(false);
    }
  }
  getHeaderClass(): string {
    const classes = ["site-header", "r"];
    if (this.state.isPinned || this.state.headerPinned) {
      classes.push("fixed");
      if (this.state.isActive || this.state.headerActive) {
        classes.push("active");
      }
      if (this.state.isInactive) {
        classes.push("inactive");
      }
    }

    return classes.join(" ");
  }
  getLanguageLink() {
    const otherLanguage = this.props.context.language === 'pl' ? 'en' : 'pl';
    const otherTitle = this.props.context.language === 'pl' ? 'English' : 'Polish';
    const mapItem = this.props.context && this.props.context.map && this.props.context.map.find((mapItem) => {
      return mapItem.language === otherLanguage;
    })
    if (!mapItem) {
      return;
    }
    const path = mapItem.path.startsWith('/') ? mapItem.path : '/' + mapItem.path;
    return <Link
      className=""
      title={otherTitle}
      to={path}
      itemProp="url"
    >
      {mapItem.language.toLocaleUpperCase()}
    </Link>
  }
  render() {
    return (
      <header className={this.getHeaderClass()}>
        <ALink
          className="logo :a-cb r"
          title="Aplinet"
          to="/"
          language={this.props.context.language}
        >
          <Logo />
        </ALink>
        <div className="a-r r :a-cr">
          <ul
            className="sub-menu r :a-cr"
            style={
              this.state.headerColor && !this.state.isPinned
                ? { color: this.state.headerColor }
                : {}
            }
          >
            <li>
              {this.props.context.language === 'pl' ? <a href="#" title="Wypełnij formularz do wyceny">
                WYPEŁNIJ BRIEF
              </a> : <a href="#" title="Fill a form to estimate your project">
                  ESTIMATE PROJECT
              </a>}

            </li>
            <li>
              {this.getLanguageLink()}
            </li>
          </ul>
          <button
            title="Pokaż menu"
            style={
              this.state.headerColor && !this.state.isPinned
                ? { stroke: this.state.headerColor }
                : {}
            }
            className={
              "hamburger-button " + (this.props.menuVisible ? "active" : "")
            }
            type="button"
          >
            <Hamburger onClick={this.toggleMenu} />
          </button>
        </div>
      </header>
    );
  }
}

export default MenuNavbar;
