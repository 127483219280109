/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useMemo, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import "../../styles/global.scss";
import "./layout.scss";
import { PageContext, PostPageContext } from "../../../types";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import ShowLess from "../../images/svg/show-less.inline.svg";
import { GlobalState } from "./global-state";

interface Props {
  children?: any;
  onMouseMove?: (event: MouseEvent) => any;
  pageContext: PageContext | PostPageContext;
}

// const renderModalContent = (closeTo: any, props: Props) => {
//   console.log('renderModalContent');
//   return (
//     <>
//       <Link
//         className="close-modal less-button"
//         to={closeTo}
//         state={{ noScroll: true }}
//       >
//         <ShowLess />
//       </Link>
//       <main className="in-modal" onMouseMove={props.onMouseMove}>
//         {props.children}
//       </main>
//     </>
//   );
// };

interface LayoutState {
  headerColor: string | null;
  headerPinned: boolean | null;
  headerActive: boolean | null;
  noFooter: boolean;
}

export const defaultLayoutState: LayoutState = {
  headerColor: null,
  headerPinned: false,
  headerActive: true,
  noFooter: false,
};
const Layout = (props: Props) => {
  const [layoutState, setState] = useState(defaultLayoutState);


  const value = useMemo(() => ({ layoutState, setState }), [
    layoutState,
    setState,
  ]);
  console.log("render layout");
  return (
    <>
      <Header context={props.pageContext} />
      <main onMouseMove={props.onMouseMove}>{props.children}</main>
      {props.pageContext.noFooter ? null : <Footer context={props.pageContext} />}
    </>
  );
};

export default Layout;
