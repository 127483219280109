/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import ALink from "../a-link/a-link";

interface Props {
  children?: any;
}


const Offer = (props: any, { children }: Props) => {
  const language = props.language ? props.language : 'pl';
  const links = {
    'pl': [
      { name: "Strony internetowe", to: '/strony-internetowe' },
      { name: "Sklepy internetowe", to: '/sklepy-internetowe' },
      { name: "Portale internetowe", to: '/portale-internetowe' },
      { name: "Aplikacje webowe", to: '/aplikacje' },
      { name: "Aplikacje mobilne", to: '/aplikacje-mobilne' },
      { name: "Projektowanie graficzne", to: '/projektowanie-graficzne' },
      { name: "Konsulting", to: '/konsulting' },
    ], 'en': [
      {
        name: "Websites",
        to: "/en/websites",
      },
      {
        name: "E-commerce",
        to: "/en/e-commerce",
      },
      {
        name: "Web platforms",
        to: "/en/web-platforms",
      },
      {
        name: "Web apps",
        to: "/web-apps",
      },
      {
        name: "Mobile apps",
        to: "/en/mobile-apps",
      },
      {
        name: "Design",
        to: "/en/design",
      },
      {
        name: "Consulting and business planning",
        to: "/en/consulting",
      }
    ]
  };
  return (
    <>
      <h4>{language === 'pl' ? 'Oferta' : 'Offer'}</h4>
      <nav>
        <ul itemType="http://schema.org/SiteNavigationElement">
          {links[language].map((link) => {
            return <li key={link.name}>
              <Link
                title={link.name}
                to={link.to}
                itemProp="url"
              >
                <span itemProp="name">{link.name}</span>
              </Link>
            </li>
          })}
        </ul>
      </nav>
    </>
  );
};

export default Offer;
