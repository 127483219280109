/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../../utils/typography";
import Facebook from "../../images/social-logo/sygnet/icon-facebook-white.inline.svg";
import Behance from "../../images/social-logo/sygnet/icon-behance-white.inline.svg";
import Pinterest from "../../images/social-logo/sygnet/icon-pinterest-white.inline.svg";
import LinkedIn from "../../images/social-logo/sygnet/icon-linkedin-white.inline.svg";

interface Props {
  children?: any;
}

const Contact = ({ children, language }: Props) => {
  return (
    <>
      <h4>{language === 'pl' ? 'Dane agencji' : 'Details'}</h4>
      <address itemScope itemType="http://schema.org/Organization">
        <p style={{
          marginBottom: rhythm(1 / 6),
        }}>
          <strong
            className="text-primary"
            itemProp="name"

          >
            Aplinet
          </strong>
        </p>
        <p
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          <span itemProp="streetAddress">ul. Promienista 93/24</span>
          <br />
          <span itemProp="postalCode">61-141</span>{" "}
          <span itemProp="addressLocality">Poznań, Polska</span>
          <br />
          {language === 'pl' ? 'Biuro czynne Pn - Pt: 9:00-17:00' : 'Business hours Mo. - Fr.: 9 am - 5 pm'}
          <br />
          <span style={{ lineHeight:"2em"}}>{language === 'pl' ? 'lub' : 'or'}</span>
          <br />
          <span itemProp="streetAddress">Kuźniki 12,</span>{" "}
          <br />
          <span itemProp="postalCode">63-500</span>{" "}
          <span itemProp="addressLocality">Ostrzeszów</span>
        </p>
        <a itemProp="telephone" href="tel:665035451">
          tel.: (+48) 665 035 451
        </a>
        <br />
        <a itemProp="email" href="mailto:biuro@aplinet.pl">
          e-mail: biuro@aplinet.pl
        </a>
      </address>

      <div className="r social-icon">
        <a
          title="Facebook"
          name="Link do strony Facebook"
          href="https://www.facebook.com/Aplinet-934513610214612"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook />
        </a>
        <a
          title="LinkedIn"
          name="Link do strony LinkedIn"
          href="https://www.linkedin.com/company/aplinet-agencja-interaktywna"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn />
        </a>
        <a
          title="Behance"
          name="Link do strony Beehance"
          href="https://www.behance.net/piotrdziurla/"
          target="_blank"
          rel="noreferrer"
        >
          <Behance />
        </a>
        <a
          title="Pinterest"
          name="Link do strony Pinterest"
          href="https://pl.pinterest.com/aplinet_agencja_interaktywna/"
          target="_blank"
          rel="noreferrer"
        >
          <Pinterest />
        </a>
      </div>
    </>
  );
};

export default Contact;
