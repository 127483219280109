import { triggerAsyncId } from "async_hooks";

export interface LayoutState {
  headerColor: string | null;
  headerPinned: boolean | null;
  headerActive: boolean | null;
  noFooter: boolean;
  [key: string]: any;
}
let state: LayoutState = {
  headerColor: null,
  headerPinned: false,
  headerActive: true,
  noFooter: true,
};

interface ObserverItem {
  callback: (
    currentState: LayoutState,
    previousState: LayoutState,
    diffState: Partial<LayoutState>
  ) => any;
  fields: string[];
}
const observers: Set<ObserverItem> = new Set();
function trigger(currentState: LayoutState, previousState: LayoutState) {
  observers.forEach(observer => {
    if (!observer.fields.length) {
      observer.callback(currentState, previousState, currentState);
    } else {
      const diffState: Partial<LayoutState> = {};
      for (let i = 0; i < observer.fields.length; i++) {
        const field = observer.fields[i];
        if (currentState[field] !== previousState[field]) {
          diffState[field] = currentState[field];
          observer.callback(currentState, previousState, diffState);
          return;
        }
      }
    }
  });
}

export class GlobalState {
  static set(value: Partial<LayoutState>) {
    const newState = { ...state, ...value };
    trigger(newState, state);
    state = newState;
  }
  static get() {
    return state;
  }
  static off(
    callback: (
      currentState: LayoutState,
      previousState: LayoutState,
      diffState: Partial<LayoutState>
    ) => any
  ) {
    for (let observerItem of observers) {
      if (observerItem.callback === callback) {
        observers.delete(observerItem);
        break;
      }
    }
  }
  static on(
    callback: (
      currentState: LayoutState,
      previousState: LayoutState,
      diffState: Partial<LayoutState>
    ) => any,
    fields: string[]
  ) {
    observers.add({ callback, fields });
  }
}
