import { Link } from "gatsby";
import React from "react";
import Logo from "../../images/svg/aplinet_logo_full.inline.svg";
import Hamburger from "../../images/svg/hamburger.inline.svg";
import TileBackground from "./tile-background";
import SVGEngine from "./svg-engine.js";
import "./menu-overlay.scss";
import "./tile-background.scss";
import ALink from "../a-link/a-link";
import { PageContext, PostPageContext } from "../../../types";

interface Props {
  context: PageContext | PostPageContext;
  activeCallback: (isActive: boolean) => any;
  menuVisible: boolean;
}
class MenuOverlay extends React.Component {
  svgEngine: SVGEngine | null = null;
  playing = false;
  state: {
    overlayActive: false;
    animationState: string;
    isDisabledActive: boolean;
  } = {
      overlayActive: false,
      isDisabledActive: false,
      animationState: "stopped",
    };

  // links =
  //   {
  //     'pl': {
  //       submenuAbout: [
  //         { name: "Kim jesteśmy", to: "/o-nas" },
  //         { name: "Brandy", to: "/brandy" },
  //         { name: "Klienci", to: "/klienci" },
  //       ],
  //       submenuOffer: [
  //         { name: "Strony internetowe", to: "/strony-internetowe" },
  //         { name: "E-commerce", to: "/sklepy-internetowe" },
  //         { name: "Portale www", to: "/portale-internetowe" },
  //         { name: "Aplikacje webowe", to: "/aplikacje" },
  //         { name: "Aplikacje mobilne", to: "/aplikacje-mobilne" },
  //         { name: "Grafika 2D i 3D", to: "/projektowanie-graficzne" },
  //         { name: "Konsulting", to: "/konsulting" },
  //         { name: "Administracja", to: "/administracja" },
  //       ],

  //       submenuPortfolio: [
  //         { name: "Strony internetowe", to: "/realizacje#strony-internetowe" },
  //         { name: "E-commerce", to: "/realizacje#e-commerce" },
  //         { name: "Systemy dedykowane", to: "/realizacje#systemy-dedykowane" },
  //         { name: "Grafika", to: "/realizacje#grafika" },
  //       ],
  //     },
  //     'en': {
  //       submenuAbout: [
  //         { name: "About us", to: "/en/about" },
  //         { name: "Brands", to: "/en/brands" },
  //         { name: "Customers", to: "/en/customers" },
  //       ],
  //       submenuOffer: [
  //         { name: "Websites ", to: "/en/websites" },
  //         { name: "E-commerce", to: "/en/e-commerce" },
  //         { name: "Web portals", to: "/en/www-platform" },
  //         { name: "Web apps", to: "/en/web-apps" },
  //         { name: "Mobile apps", to: "/en/mobile-apps" },
  //         { name: "2D & 3D Design", to: "/en/design" },
  //         { name: "Consulting", to: "/en/consulting" },
  //         { name: "Administration", to: "/en/administration" },
  //       ],

  //       submenuPortfolio: [
  //         { name: "Websites", to: "/en/portfolio" },
  //         { name: "E-commerce", to: "/en/portfolio" },
  //         { name: "Dedicated software", to: "/en/portfolio" },
  //         { name: "Design", to: "/en/portfolio" },
  //       ],
  //     }
  //   };
  get language() {
    if (this.props.context && this.props.context.language) {
      return this.props.context.language
    }
    return 'pl';
  }
  links =
    {
      'pl': {
        about: { name: "O nas", to: "/o-nas" },
        offer: { name: "Oferta", to: "/oferta" },
        portfolio: { name: "Realizacje", to: "/realizacje" },
        blog: { name: "Blog", to: "/blog" },
        contact: { name: "Kontakt", to: "/kontakt" },
      },
      'en': {
        about: { name: "About us", to: "/en/about" },
        offer: { name: "Offer", to: "/en/offer" },
        portfolio: { name: "Portfolio", to: "/en/portfolio" },
        blog: { name: "Blog", to: "/en/blog" },
        contact: { name: "Contact", to: "/en/contact" },
      }
    };
  constructor(public props: Props) {
    super(props);
    this.setBackgroundRef = this.setBackgroundRef.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onResize = this.onResize.bind(this);


  }
  checkResize: boolean = false;
  componentWillUnmount() {
    this.svgEngine.destroy();
    window.removeEventListener('resize', this.onResize);
  }
  onResize() {
    if (this.svgEngine.getState() === 'initial') {
      this.checkResize = false;
      this.svgEngine.init();
    } else {
      this.checkResize = true;
    }
  }
  setBackgroundRef(element: HTMLElement) {
    if (element) {
      this.svgEngine = new SVGEngine(element.querySelector("svg"));
      this.svgEngine.on("state", newState => {
        if (newState === "initial") {
          this.setState({ animationState: newState, overlayActive: false });
          if (this.checkResize) {
            this.checkResize = false;
            this.svgEngine.init();
          }
        } else {
          this.setState({ animationState: newState });
        }
      });
      if (window) {
        window.addEventListener('resize', this.onResize);
        this.svgEngine.init();
      }

    }
  }


  activate(value: boolean) {
    if (this.svgEngine) {
      if (value) {
        if (!this.state.overlayActive) {
          this.setState({ overlayActive: true });
          setTimeout(() => {
            if (this.svgEngine) {
              this.svgEngine.play();
            }
          });
        } else {
          this.svgEngine.play();
        }
      } else {
        this.svgEngine.reverse();
      }
    }
  }

  getOverlayClass() {
    let result = "";
    if (this.state.overlayActive) {
      result += " active";
    }
    if (this.state.isDisabledActive) {
      result += " disable-active";
    }
    return result;
  }
  onMouseOver(event: MouseEvent) {
    if (
      event.target &&
      event.target.classList.contains("background-text") &&
      !event.target.classList.contains("active")
    ) {
      if (!this.state.isDisabledActive) {
        this.setState({ isDisabledActive: true });
      }
    } else {
      if (this.state.isDisabledActive) {
        this.setState({ isDisabledActive: false });
      }
    }
  }
  closeMenu() {
    this.props.activeCallback(false);
  }
  render() {
    return (
      <section
        className={"menu-overlay " + this.getOverlayClass()}
        onMouseOver={this.onMouseOver}
      >
        <div className="menu-content r container-xs ">
          <nav className="r :a-cc a-cc g !g:xxs !g:xs ">
            <ul className="r :a-et g :g :c-2 column-wrapper">
              <li>
                <h2>
                  <Link
                    to={this.links[this.language].about.to}
                    activeClassName="active"
                    title={this.links[this.language].about.name}
                    className="background-text"
                    partiallyActive={true}
                    onClick={this.closeMenu}>

                    {this.links[this.language].about.name}
                  </Link>
                </h2>

              </li>

              <li>
                <h2>
                  <Link
                    to={this.links[this.language].offer.to}
                    activeClassName="active"
                    title={this.links[this.language].offer.name}
                    className="background-text"
                    partiallyActive={true}
                    onClick={this.closeMenu}>

                    {this.links[this.language].offer.name}
                  </Link>

                </h2>

              </li>
              <li>
                <h2>
                  <Link
                    to={this.links[this.language].portfolio.to}
                    activeClassName="active"
                    title={this.links[this.language].portfolio.name}
                    className="background-text"
                    partiallyActive={true}
                    onClick={this.closeMenu}>

                    {this.links[this.language].portfolio.name}
                  </Link>

                </h2>

              </li>
              {/* <li>
                <h2>
                  <Link
                    to={this.links[this.language].blog.to}
                    activeClassName="active"
                    title={this.links[this.language].blog.name}
                    className="background-text"
                    partiallyActive={true}
                    onClick={this.closeMenu}>

                    {this.links[this.language].blog.name}
                  </Link>

                </h2>

              </li> */}
              <li>
                <h2>
                  <Link
                    to={this.links[this.language].contact.to}
                    activeClassName="active"
                    title={this.links[this.language].contact.name}
                    className="background-text"
                    partiallyActive={true}
                    onClick={this.closeMenu}>

                    {this.links[this.language].contact.name}
                  </Link>

                </h2>

              </li>
            </ul>
          </nav>
        </div>
        <TileBackground refCallback={this.setBackgroundRef} />
      </section>
    );
  }
}

export default MenuOverlay;
