// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-about-tsx": () => import("./../../../src/templates/about/about.tsx" /* webpackChunkName: "component---src-templates-about-about-tsx" */),
  "component---src-templates-about-o-nas-tsx": () => import("./../../../src/templates/about/o-nas.tsx" /* webpackChunkName: "component---src-templates-about-o-nas-tsx" */),
  "component---src-templates-administration-administracja-tsx": () => import("./../../../src/templates/administration/administracja.tsx" /* webpackChunkName: "component---src-templates-administration-administracja-tsx" */),
  "component---src-templates-administration-support-tsx": () => import("./../../../src/templates/administration/support.tsx" /* webpackChunkName: "component---src-templates-administration-support-tsx" */),
  "component---src-templates-aplications-aplikacje-tsx": () => import("./../../../src/templates/aplications/aplikacje.tsx" /* webpackChunkName: "component---src-templates-aplications-aplikacje-tsx" */),
  "component---src-templates-aplications-web-apps-tsx": () => import("./../../../src/templates/aplications/web-apps.tsx" /* webpackChunkName: "component---src-templates-aplications-web-apps-tsx" */),
  "component---src-templates-brands-brands-tsx": () => import("./../../../src/templates/brands/brands.tsx" /* webpackChunkName: "component---src-templates-brands-brands-tsx" */),
  "component---src-templates-brands-brandy-tsx": () => import("./../../../src/templates/brands/brandy.tsx" /* webpackChunkName: "component---src-templates-brands-brandy-tsx" */),
  "component---src-templates-consulting-consulting-tsx": () => import("./../../../src/templates/consulting/consulting.tsx" /* webpackChunkName: "component---src-templates-consulting-consulting-tsx" */),
  "component---src-templates-consulting-konsulting-tsx": () => import("./../../../src/templates/consulting/konsulting.tsx" /* webpackChunkName: "component---src-templates-consulting-konsulting-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("./../../../src/templates/contact/contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-contact-kontakt-tsx": () => import("./../../../src/templates/contact/kontakt.tsx" /* webpackChunkName: "component---src-templates-contact-kontakt-tsx" */),
  "component---src-templates-customers-customers-tsx": () => import("./../../../src/templates/customers/customers.tsx" /* webpackChunkName: "component---src-templates-customers-customers-tsx" */),
  "component---src-templates-customers-klienci-tsx": () => import("./../../../src/templates/customers/klienci.tsx" /* webpackChunkName: "component---src-templates-customers-klienci-tsx" */),
  "component---src-templates-design-design-tsx": () => import("./../../../src/templates/design/design.tsx" /* webpackChunkName: "component---src-templates-design-design-tsx" */),
  "component---src-templates-design-projektowanie-graficzne-tsx": () => import("./../../../src/templates/design/projektowanie-graficzne.tsx" /* webpackChunkName: "component---src-templates-design-projektowanie-graficzne-tsx" */),
  "component---src-templates-e-commerce-e-commerce-tsx": () => import("./../../../src/templates/e-commerce/e-commerce.tsx" /* webpackChunkName: "component---src-templates-e-commerce-e-commerce-tsx" */),
  "component---src-templates-e-commerce-sklepy-internetowe-tsx": () => import("./../../../src/templates/e-commerce/sklepy-internetowe.tsx" /* webpackChunkName: "component---src-templates-e-commerce-sklepy-internetowe-tsx" */),
  "component---src-templates-index-en-tsx": () => import("./../../../src/templates/index/en.tsx" /* webpackChunkName: "component---src-templates-index-en-tsx" */),
  "component---src-templates-index-pl-tsx": () => import("./../../../src/templates/index/pl.tsx" /* webpackChunkName: "component---src-templates-index-pl-tsx" */),
  "component---src-templates-mobile-aplications-aplikacje-mobilne-tsx": () => import("./../../../src/templates/mobile-aplications/aplikacje-mobilne.tsx" /* webpackChunkName: "component---src-templates-mobile-aplications-aplikacje-mobilne-tsx" */),
  "component---src-templates-mobile-aplications-mobile-apps-tsx": () => import("./../../../src/templates/mobile-aplications/mobile-apps.tsx" /* webpackChunkName: "component---src-templates-mobile-aplications-mobile-apps-tsx" */),
  "component---src-templates-offer-item-oferta-item-tsx": () => import("./../../../src/templates/offer-item/oferta-item.tsx" /* webpackChunkName: "component---src-templates-offer-item-oferta-item-tsx" */),
  "component---src-templates-offer-item-offer-item-tsx": () => import("./../../../src/templates/offer-item/offer-item.tsx" /* webpackChunkName: "component---src-templates-offer-item-offer-item-tsx" */),
  "component---src-templates-offer-oferta-tsx": () => import("./../../../src/templates/offer/oferta.tsx" /* webpackChunkName: "component---src-templates-offer-oferta-tsx" */),
  "component---src-templates-offer-offer-tsx": () => import("./../../../src/templates/offer/offer.tsx" /* webpackChunkName: "component---src-templates-offer-offer-tsx" */),
  "component---src-templates-portfolio-portfolio-en-tsx": () => import("./../../../src/templates/portfolio/portfolio_en.tsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-en-tsx" */),
  "component---src-templates-portfolio-portfolio-item-tsx": () => import("./../../../src/templates/portfolio/portfolio-item.tsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-item-tsx" */),
  "component---src-templates-portfolio-portfolio-tsx": () => import("./../../../src/templates/portfolio/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-tsx" */),
  "component---src-templates-privacy-policy-polityka-prywatnosci-tsx": () => import("./../../../src/templates/privacy-policy/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-polityka-prywatnosci-tsx" */),
  "component---src-templates-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-range-range-item-en-tsx": () => import("./../../../src/templates/range/range-item.en.tsx" /* webpackChunkName: "component---src-templates-range-range-item-en-tsx" */),
  "component---src-templates-range-range-item-tsx": () => import("./../../../src/templates/range/range-item.tsx" /* webpackChunkName: "component---src-templates-range-range-item-tsx" */),
  "component---src-templates-services-outsourcing-tsx": () => import("./../../../src/templates/services/outsourcing.tsx" /* webpackChunkName: "component---src-templates-services-outsourcing-tsx" */),
  "component---src-templates-services-services-tsx": () => import("./../../../src/templates/services/services.tsx" /* webpackChunkName: "component---src-templates-services-services-tsx" */),
  "component---src-templates-websites-strony-internetowe-tsx": () => import("./../../../src/templates/websites/strony-internetowe.tsx" /* webpackChunkName: "component---src-templates-websites-strony-internetowe-tsx" */),
  "component---src-templates-websites-websites-tsx": () => import("./../../../src/templates/websites/websites.tsx" /* webpackChunkName: "component---src-templates-websites-websites-tsx" */),
  "component---src-templates-www-platform-portale-internetowe-tsx": () => import("./../../../src/templates/www-platform/portale-internetowe.tsx" /* webpackChunkName: "component---src-templates-www-platform-portale-internetowe-tsx" */),
  "component---src-templates-www-platform-www-platform-tsx": () => import("./../../../src/templates/www-platform/www-platform.tsx" /* webpackChunkName: "component---src-templates-www-platform-www-platform-tsx" */)
}

